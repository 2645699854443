<template xmlns="http://www.w3.org/1999/html">
  <div class="container is-fluid">
    <div class="columns is-centered is-variable is-4">
      <div class="column is-2">
        <img src="../assets/logo.svg">
      </div>
      <div class="column is-4" style="display: flex; align-items: center; justify-content: flex-end">
        <h4 class="page-header-24">
          <b-icon icon="finance"></b-icon>
          {{ $ml.get('index_graph_head') }}
        </h4>
      </div>
      <div class="column is-2" style="display: flex; align-items: center; justify-content: flex-end">
        <b-field>
          <b-select v-model="chosenBk" @change.native="bkStat" icon="tag" expanded>
            <option value="">{{ $ml.get('index_graph_select_total') }}</option>
            <option v-for="option in bookmakers" :value="option" :key="option">
              {{ option }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns is-centered" v-if="showGraph">
      <div class="column is-8">
        <div class="dash-plate box">
          <ProfitGraph
              v-if="showGraph"
              :chartdata="bkProfitData !== null ? bkProfitData : profitData"
              :options="profitOptions"
          />
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <i style="font-size: 11px;">{{ $ml.get('index_graph_head_note') }}</i>
    </div>
    <div class="columns is-centered is-variable is-4">
      <div class="column is-8">
        <h4 class="page-header-24">
          <b-icon icon="star"></b-icon>
          {{ $ml.get('index_last_bets') }}
        </h4>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-8">
        <div class="dash-plate box" style="height: 200px; overflow-x: hidden; overflow-y: auto">
          <span v-if="bets.length === 0">
            {{ $ml.get('index_no_open_bets') }}
          </span>
          <b-table
              v-if="bets.length > 0"
              :data="bets"
              :paginated="false"
              :striped="true"
              :show-header="false"
              :loading="loading">
            <b-table-column field="bookmaker" width="150" v-slot="props">
              <Bk :bk="props.row.bookmaker"/>
            </b-table-column>
            <b-table-column field="sport" v-slot="props">
              <div class="columns">
                <div class="column is-7 rightal">
                  <span>{{ props.row.home_team }} - {{ props.row.away_team }}</span> <span
                    v-if="props.row.score">[{{ props.row.score }}]</span>
                </div>
                <div class="column is-1 has-text-centered">
                  <Sport v-bind:sport="props.row.sport"/>
                </div>
                <div class="column is-4 leftal">
                  <BkLine :market="props.row.market" :target="props.row.target" :pivot="props.row.pivot"/>
                  <span>{{ props.row.coef }}</span>
                </div>
              </div>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sport from "@/components/Sport";
import Vue from "vue";
import ProfitGraph from "@/components/ProfitGraph";
import BkLine from "@/components/BkLine";
import Bk from "@/components/Bk";
import {mapGetters} from "vuex";

export default {
  name: "Home",
  data() {
    return {
      loading: false,
      update_ival: null,
      showGraph: false,
      profitData: null,
      bkProfitData: null,
      chosenBk: '',
      bets: [],
      profitOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }]
        },
      },
    }
  },
  computed: {
    ...mapGetters('data', ['bookmakers']),
  },
  components: {Bk, BkLine, ProfitGraph, Sport},
  methods: {
    retrieve: function () {
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `index/index`).then(resp => {
        this.profitData = {
          labels: resp.data.result.profitDates,
          datasets: [
            {
              borderColor: '#228BE6',
              borderWidth: 3,
              backgroundColor: 'rgba(0,0,0,0)',
              pointBackgroundColor: '#fff',
              pointBorderColor: '#1864AB',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverRadius: 6,
              pointHoverBackgroundColor: '#1864AB',
              data: resp.data.result.profitStat,
              tension: 0,
            }
          ]
        }
        if (resp.data.result.profitDates.length > 0) {
          this.showGraph = true
        }
        this.bets = resp.data.result.bets
      })
    },
    bkStat: function () {
      if (this.chosenBk === '') {
        this.bkProfitData = null

        return
      }
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `statistic/bk?bk=` + this.chosenBk).then(resp => {
        this.bkProfitData = {
          labels: resp.data.result.profitDates,
          datasets: [
            {
              borderColor: '#228BE6',
              borderWidth: 3,
              backgroundColor: 'rgba(0,0,0,0)',
              pointBackgroundColor: '#fff',
              pointBorderColor: '#1864AB',
              pointBorderWidth: 2,
              pointRadius: 5,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: '#1864AB',
              data: resp.data.result.profitStat,
              tension: 0
            }
          ]
        }
      })
    }
  },
  async mounted() {
    this.retrieve()
    this.update_ival = setInterval(() => {
      this.retrieve()
    }, 120000)
  },
  beforeDestroy() {
    if (this.update_ival) {
      clearInterval(this.update_ival)
    }
  },
}
</script>

<style scoped>

</style>